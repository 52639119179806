<template>
  <div>
    <topbar :title="titleBar"></topbar>
    <div class="placeBox">
      <div class="topPlace">
        <div class="iconBox">
          <i class="locaIcon"></i>
          <span>{{ place }}</span>
        </div>
        <span class="edit-btn" @click="chengePlace">{{
          lanType == 1 ? "更改" : "Edit"
        }}</span>
      </div>
      <div class="detailBox">
        <p class="detail">
          <span class="XXDZ">{{ xiangXiDiZhi }}</span>
          <input
            v-model.trim="detailPlace"
            type="text"
            :placeholder="shuRuName"
            maxlength="16"
            @input="setLength(1)"
          />
        </p>
        <div class="detailHouse">
          <input
            v-model.trim="houseNum"
            type="text"
            :placeholder="zuoShuo"
            maxlength="5"
            @input="setLength(2)"
          />
          <input
            v-model.trim="floorNum"
            type="text"
            :placeholder="cengShu"
            maxlength="5"
            @input="setLength(3)"
          />
          <input
            v-model.trim="workPlace"
            type="text"
            :placeholder="danWei"
            maxlength="5"
            @input="setLength(4)"
          />
        </div>
        <div class="detail">
          <span>{{ lable }}</span>
          <div class="placeTag">
            <p
              v-for="item in $store.state.addressTag"
              :key="item.tagId"
              @click="selectTag(item.tagId)"
              :class="{ active: tag == item.tagId }"
            >
              <i
                :class="{
                  home: item.tagId == 1,
                  company: item.tagId == 2,
                  other: item.tagId == 11,
                  school: item.tagId == 4,
                }"
              ></i>
              {{ item.tagName }}
            </p>
          </div>
        </div>
        <p class="detail detail-warn">
          <span>{{ new_XDZ_beiZhu }}</span>
          <textarea
            v-model.trim="warn"
            maxlength="26"
            type="text"
            :placeholder="DZ_26"
            @input="setLength(5)"
          ></textarea>
        </p>
      </div>
      <div class="detailBox">
        <p class="detail">
          <span>{{ lianXiRen }}</span>
          <input v-model.trim="phoneUser" type="text" :placeholder="DZ_name" />
        </p>
        <div class="detail">
          <span>{{ gender }}</span>
          <div class="placeTag">
            <p
              v-for="item in sexList"
              :key="item.tagId"
              @click="selectSex(item.titlesId)"
              :class="{ active: sex == item.titlesId }"
            >
              {{ item.titlesName }}
            </p>
          </div>
        </div>
        <div class="detail">
          <span>{{ shouJiHaoMa }}</span>
          <div class="phone">
            <div class="phone-1">
              <span @click="selectPhone">+{{ phoneCode }}</span>
              <!-- <i style="width: 0.16rem"></i> -->
              <div class="phoneBox" :class="{ active: phoneShow }">
                <p @click="selectCode(852)">+852</p>
                <p @click="selectCode(823)">+823</p>
              </div>
            </div>
            <input
              v-model.trim="phonehandle"
              type="number"
              :placeholder="shouJiHaoMa"
              @input="setPhoneLength"
            />
          </div>
        </div>
        <p class="detail">
          <span>{{ jieShouKFC }}</span>
          <input v-model.trim="phone" type="text" :placeholder="DZ_DianYou" />
        </p>
        <p class="detail" @click="checkDefault">
          <i :class="{ active: defaultPlace }"></i>
          <span>{{ new_XDZ_moRenDiZhi }}</span>
        </p>
      </div>
    </div>
    <!-- 确定按钮 -->
    <div class="finishBtn" @click="addPlace">{{ save }}</div>
  </div>
</template>
<script>
import Topbar from "../../components/Topbar.vue";
import { postPaas } from "../../assets/utils/request";
import language from "../../assets/js/language";

export default {
  name: "SendPlace",
  data() {
    return {
      tag: null, //标签
      defaultPlace: false,
      detailPlace: "", //详细地址
      houseNum: "", //作数
      floorNum: "", //层数
      workPlace: "", //单位
      warn: "", //备注
      phoneUser: "", //联系人
      sex: "", //性别
      phonehandle: "", //手提电话
      phone: "", //电话
      lanType: this.$store.state.language,
      titleBar:
        this.$store.state.language == 1 ? "添加送餐地址" : "Add Address",
      alertText: this.$store.state.language == 1 ? "添加成功" : "Add success",
      pageParam: null,
      canClick: true,
      phoneShow: false,
      phoneCode: "852",
      // 靜態文字
      xiangXiDiZhi: "",
      zuoShuo: "",
      cengShu: "",
      danWei: "",
      lable: "",
      new_XDZ_beiZhu: "",
      xiaoYu26: "",
      lianXiRen: "",
      name: "",
      yuSheDiZhi: "",
      save: "",
      saved: "",
      gender: "",
      shouJiHaoMa: "",
      jieShouKFC: "",
      new_XDZ_moRenDiZhi: "",
      DZ_26: "",
      DZ_name: "",
      DZ_DianYou: "",
      shuRuName: "",
      QXZDZ: "",
      QTXLXR: "",
      QTXXXDZ: "",
      SJHCW: "",
      HQXXSB:""
    };
  },
  methods: {
    selectPhone() {
      // this.phoneShow = !this.phoneShow;
    },
    selectCode(code) {
      this.phoneCode = code;
      this.selectPhone();
    },
    selectTag(type) {
      this.tag = type;
    },
    selectSex(type) {
      this.sex = type;
    },
    checkDefault() {
      this.defaultPlace = !this.defaultPlace;
    },
    setPhoneLength() {
      if (this.phonehandle.length > 8) {
        this.phonehandle = this.phonehandle.slice(0, 8);
      }
    },
    setLength(type) {
      var num;
      var str;
      if (type == 1) {
        str = this.detailPlace;
        num = 16;
      } else if (type == 2) {
        str = this.houseNum;
        num = 5;
      } else if (type == 3) {
        str = this.floorNum;
        num = 5;
      } else if (type == 4) {
        str = this.workPlace;
        num = 5;
      } else if (type == 5) {
        str = this.warn;
        num = 26;
      }
      var TC = this.getStrLength(str);
      var E = str.length - TC;
      var all = TC * 2 + E;
      if (all > num) {
        if (type == 1) {
          this.detailPlace = this.detailPlace.slice(0, str.length - 1);
        } else if (type == 2) {
          this.houseNum = this.houseNum.slice(0, str.length - 1);
        } else if (type == 3) {
          this.floorNum = this.floorNum.slice(0, str.length - 1);
        } else if (type == 4) {
          this.workPlace = this.workPlace.slice(0, str.length - 1);
        } else if (type == 5) {
          this.warn = this.warn.slice(0, str.length - 1);
        }
      }
    },
    // 更换地址
    chengePlace() {
      this.$router.push({ path: "/selectShop", query: { type: 1, add: true } });
    },
    // 添加地址
    async addPlace() {
      if (!this.phoneUser) {
        return this.$toast(this.QTXLXR);
      }
      if (!this.phonehandle || this.phonehandle.length != 8) {
        return this.$toast(this.SJHCW);
      }
      if (!this.detailPlace) {
        return this.$toast(this.QTXXXDZ);
      }
      if (!this.sex) {
        return ;
      }
      var params = {
        actionName: "candao.member.addressCreate",
        content: {
          contactPerson: this.phoneUser,
          phoneNo: this.phonehandle,
          areaCode: this.phoneCode,
          email: this.phone,
          cityId: 3, //3 香港
          street: this.detailPlace,
          block: this.houseNum,
          floor: this.floorNum,
          flat: this.workPlace,
          isDefault: this.defaultPlace,
          tagId: this.tag,
          titlesId: this.sex,
          district: "",
        },
      };
      if (this.pageParam) {
        params.actionName = "candao.member.addressUpdate";
        params.content.addressCode = this.pageParam.addressCode;
        params.content.district = this.pageParam.district || "";
      }
      if (this.place) {
        params.content.building = this.place;
      }
      if (this.$store.state.addPlaceInfo) {
        params.content.district = this.$store.state.addPlaceInfo.district || "";
      }
      if (this.location) {
        params.content.lat = this.location[0];
        params.content.lng = this.location[1];
      }
      if (!params.content.lat || !params.content.lng) {
        return this.$toast(this.QXZDZ);
      }
      let result = await postPaas("UserUnify", params);
      if (result.status == 1) {
        this.$toast(this.alertText);
        this.$store.commit("clearAddPlaceInfo");
        setTimeout(() => {
          this.$router.back(-1);
        }, 500);
      } else {
        this.$toast(this.HQXXSB);
      }
    },
    getStrLength(str) {
      var A = str.match(/[^\x00-\x80]/g) || [];
      return A.length;
    },
    setLanguage() {
      var type = this.$store.state.language;
      var lanType = "chinese";
      if (type != 1) {
        lanType = "english";
      }
      this.xiangXiDiZhi = language.xiangXiDiZhi[lanType];
      this.zuoShuo = language.zuoShuo[lanType];
      this.cengShu = language.cengShu[lanType];
      this.danWei = language.danWei[lanType];
      this.lable = language.lable[lanType];
      this.new_XDZ_beiZhu = language.new_XDZ_beiZhu[lanType];
      this.xiaoYu26 = language.xiaoYu26[lanType];
      this.lianXiRen = language.lianXiRen[lanType];
      this.name = language.name[lanType];
      this.yuSheDiZhi = language.yuSheDiZhi[lanType];
      this.saved = language.saved[lanType];
      this.gender = language.gender[lanType];
      this.shouJiHaoMa = language.shouJiHaoMa[lanType];
      this.jieShouKFC = language.jieShouKFC[lanType];
      this.new_XDZ_moRenDiZhi = language.new_XDZ_moRenDiZhi[lanType];
      this.DZ_26 = language.DZ_26[lanType];
      this.DZ_name = language.DZ_name[lanType];
      this.DZ_DianYou = language.DZ_DianYou[lanType];
      this.shuRuName = language.shuRuName[lanType];
      this.QXZDZ = language.QXZDZ[lanType];
      this.QTXLXR = language.QTXLXR[lanType];
      this.QTXXXDZ = language.QTXXXDZ[lanType];
      this.SJHCW = language.SJHCW[lanType];
      this.HQXXSB=language.HQXXSB[lanType]

      if (this.pageParam) {
        this.save = language.save[lanType];
      } else {
        this.save = language.confirm[lanType];
      }
    },
  },
  computed: {
    sexList() {
      var title = this.$store.state.userTitle;
      return title || [];
    },
    place() {
      var p = "";
      if (this.$store.state.addPlaceInfo) {
        p = this.$store.state.addPlaceInfo.building;
      } else if (this.pageParam) {
        p = this.pageParam.building;
      }
      return p;
    },
    location() {
      var l = null;
      if (this.$store.state.addPlaceInfo) {
        l = [
          this.$store.state.addPlaceInfo.lat,
          this.$store.state.addPlaceInfo.lng,
        ];
      } else if (this.pageParam) {
        l = [this.pageParam.lat, this.pageParam.lng];
      }
      return l;
    },
  },
  beforeRouteLeave(to) {
    if (to.name != "SelectShop") {
      this.$store.commit("clearAddPlaceInfo");
      this.$store.commit("clearRouter");
    } else {
      this.$store.commit("saveRouter", "SendPlace");
    }
  },
  beforeRouteEnter(to, from) {
    if (from.name != "SelectShop") {
      localStorage.removeItem("KFCAddP");
    }
  },
  created() {
    var params = this.$route.query.place;
    if (params) {
      params = JSON.parse(decodeURIComponent(params));
      // this.location = [params.lat, params.lng];
      this.pageParam = params;
      // this.place = params.building;
      this.detailPlace = params.street;
      this.tag = params.tagId;
      this.defaultPlace = params.isDefault;
      this.houseNum = params.block;
      this.floorNum = params.floor;
      this.workPlace = params.flat;
      this.phoneUser = params.contactPerson;
      this.sex = params.titlesId;
      this.phonehandle = params.phoneNo;
      this.phone = params.email;
      this.titleBar =
        this.$store.state.language == 1 ? "更改送餐地址" : "Update Address";
      this.alertText =
        this.$store.state.language == 1 ? "修改成功" : "Update success";
    }
    this.setLanguage();
  },
  components: {
    Topbar,
  },
};
</script>
<style scoped>
.placeBox {
  background-color: #f2f3f5;
  padding: 0.16rem;
  box-sizing: border-box;
}
.topPlace {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 0.08rem;
  height: 0.54rem;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #03060d;
  padding: 0.16rem;
  box-sizing: border-box;
}
.iconBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.locaIcon {
  width: 0.24rem;
  height: 0.27rem;
  background: url("../../assets/icon/location1.png") no-repeat center;
  background-size: cover;
  margin-right: 0.08rem;
}
.edit-btn {
  width: 0.58rem;
  height: 0.29rem;
  border-radius: 0.15rem;
  font-size: 0.14rem;
  background-color: #e4022b;
  line-height: 0.29rem;
  font-family: PingFang HK;
  color: #fff;
}
.detailBox {
  background-color: #fff;
  border-radius: 0.08rem;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #03060d;
  /* padding: 0.16rem; */
  box-sizing: border-box;
  margin-top: 0.12rem;
}
.detail {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 0.54rem;
  font-size: 0.14rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #474747;
  padding: 0.16rem;
  box-sizing: border-box;
}
.detail-warn {
  height: auto;
  align-items: flex-start;
}
.detail-warn textarea {
  border: none;
  resize: none;
}
.detail-warn textarea::-webkit-scrollbar {
  display: none;
}
.detail span:first-child {
  width: 1rem;
  text-align: left;
}
.detail input {
  width: 2rem;
  font-size: 0.14rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #03060d;
  outline: none;
  border: none;
}
.detailHouse {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
.detailHouse input {
  width: 0.93rem;
  height: 0.36rem;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 0.04rem;
  font-size: 0.12rem;
  font-family: PingFang HK;
  font-weight: 400;
  color: #cacbcc;
  text-align: center;
}
.placeTag {
  width: 2.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.placeTag p {
  width: 0.6rem;
  height: 0.25rem;
  text-align: center;
  background-color: #efefef;
  margin: 0;
  line-height: 0.25rem;
  border: 1px solid none;
  color: #808080;
  font-size: 0.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.placeTag p.active {
  background-color: #e5a0b0;
  border-color: none;
  color: #03060d;
}
.phone {
  width: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.phone .phone-1 {
  width: 0.5rem;
  font-size: 0.16rem;
  font-family: Open Sans;
  font-weight: 400;
  color: #051b24;
  display: flex;
  align-items: center;
  position: relative;
}
.phone .phone-1 i {
  width: 0.16rem !important;
  height: 0.16rem;
  background: url("../../assets/icon/show.png") no-repeat center;
  background-size: cover;
  margin-left: 0.04rem;
}
.phone input {
  width: 1.2rem;
}
.finishBtn {
  width: 3.4rem;
  height: 0.44rem;
  border-radius: 0.08rem;
  background-color: #e4022b;
  font-size: 0.16rem;
  font-family: PingFang HK;
  font-weight: 600;
  line-height: 0.44rem;
  color: #ffffff;
  margin-left: 0.175rem;
}
.detail > i {
  width: 0.16rem;
  height: 0.16rem;
  margin-right: 0.08rem;
  background: url("../../assets/icon/cycle.png") no-repeat center;
  background-size: cover;
}
.detail > i.active {
  background: url("../../assets/icon/icon@2x.png") no-repeat center;
  background-size: cover;
}
.phoneBox {
  height: 0;
  position: absolute;
  top: 0.2rem;
  left: 0;
  background-color: #fff;
  overflow: hidden;
  transition: all 0.2s linear;
  font-size: 0.16rem;
}
.phoneBox.active {
  height: 60px;
}
.placeTag p i {
  margin-right: 0.04rem;
}
.placeTag .home {
  width: 0.11rem;
  height: 0.14rem;
  background: url("../../assets/icon/wq.png") no-repeat center;
  background-size: 0.11rem 0.12rem;
}
.placeTag .company {
  width: 0.14rem;
  height: 0.12rem;
  background: url("../../assets/icon/com.png") no-repeat center;
  background-size: 0.14rem 0.12rem;
}
.placeTag .other {
  width: 0.14rem;
  height: 0.14rem;
  background: url("../../assets/icon/qt.png") no-repeat center;
  background-size: 0.14rem 0.14rem;
}
.XXDZ {
  white-space: nowrap;
}
</style>